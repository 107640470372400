.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.sticky-navbar {
  position: fixed;
  top: 0;
  width: 100%;
  height: 60px;
  z-index: 1000;
  color: black !important;
  background-color: #72727246;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 0 10px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* @import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600&display=swap');

* {
  margin: 0;
  border: 0;
  padding: 0;
  outline: 0;
  font-family: 'Rubik', sans-serif;
}

*, *:after, *:before {
  box-sizing: border-box;
}

html {
  position: relative;
  overflow: hidden;
  font-size: 16px;
}

@media screen and (max-width: 768px) {
  html {
    font-size: 15px;
  }
}

@media screen and (max-width: 465px) {
  html {
    font-size: 14px;
  }
}

body {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  background: #f1f3f6;
} */

.blog-card {
  height: auto;
  background: #fff;

  padding: 1.5rem;
  overflow: hidden;
  position: relative;
  box-shadow: 0 3px 20px 0px var(--primary-l-color);
  border-radius: 1.5rem;
  transition: transform 0.2s linear;
}

.blog-card-img-holder {
  width: 100%;
  height: auto;
  position: relative;
}

.blog-card-img-holder img {
  width: 100%;
  height: auto;
  max-height: 14rem;
  height: 14rem;
  object-fit: cover;
  border-radius: 1.5rem;
}

.blog-title {
  color: #22215b;
  padding: 1rem 0;

  font-size: 1.3rem;
  padding-bottom: 0;
  /* display: -webkit-box; 
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2; 
  text-overflow: ellipsis;
  line-height: 2; 
  max-height: calc(2.6em * 2); */
}
.blog-options:hover {
  color: #f6786a !important;
}

.blog-description {
  padding: 1rem 0;
  color: #22215b80;
  font-size: 1rem;
}

.blog-time {
  font-size: 0.8rem;
  color: #22215b;
}

.blog-options {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
}
.blog-options:hover {
  color: #f6786a !important;
}
.blog-options span {
  font-weight: 600;
  color: #22215b;
}

.blog-btn {
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  font-weight: 400;
  background: #eef7fe;
  color: #22215b;
  cursor: pointer;
}
.blog-details-container {
  /* max-width: 800px; */
  margin: 2rem auto;
  padding: 28px !important;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.05);
}

.blog-details-header {
  position: relative;
}

.blog-details-banner-img {
  width: 280px;
  object-fit: contain;
  height: unset;
  border-radius: 10px;
}

.blog-details-header-content {
  padding: 1.5rem 0;
  text-align: center;
}

.blog-details-title {
  text-align: start;

  font-size: 2.5rem;
  font-weight: 700;
  color: #22215b;
}

.blog-details-author {
  text-align: start;
  font-size: 1rem;
  color: #555;
  margin-top: 0.5rem;
}

.blog-details-content {
  padding: 2rem 0;
}

.blog-details-description {
  font-size: 1.2rem;
  line-height: 1.8;
  color: #444;
  margin-bottom: 1.5rem;
}

.blog-details-html-content {
  line-height: 1.6;
  font-size: 1.1rem;
  color: #333;
}

.blog-details-html-content p {
  margin-bottom: 1rem;
}

.blog-details-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 2rem;
  border-top: 1px solid #eee;
}

.blog-details-tags {
  display: flex;
  gap: 10px;
}

.blog-details-tag {
  background-color: #eef7fe;
  color: #22215b;
  font-size: 0.9rem;
  padding: 5px 10px;
  border-radius: 5px;
}

.social-details-share {
  display: flex;
  align-items: center;
}

.social-details-share p {
  margin-right: 10px;
}

.social-details-icons {
  display: flex;
  gap: 15px;
}

.social-details-icons i {
  font-size: 1.2rem;
  color: #22215b;
  cursor: pointer;
  transition: color 0.3s;
}

.social-details-icons i:hover {
  color: #5f7fff;
}
